<template>
    <div>
        <div
            class="info-card-panner userInfo-panner info-card-panner border-all"
            style="border:none;"
        >
            <header class="panner-hd">
                <h2 class="tit">
                    <span
                        class="line"
                        style="font-size:16px;color:#666;"
                        v-if="userInfo.info.user_type==1"
                    >极客信息</span>
                    <span
                        class="line"
                        style="font-size:16px;color:#666;"
                        v-else
                    >雇主信息</span>
                </h2>
            </header>

            <div class="panner-bd">
                <div
                    class="user-info"
                    style="height:100px;"
                >
                    <div class="left pull-left avatarBox">
                        <span class="edit">修改</span>
                        <div class="avatarImg">
                            <el-upload
                                class="avatar-uploader"
                                :action="imgUploadUrl"
                                :headers="imgHeaders"
                                :show-file-list="false"
                                :before-upload="beforeUpload"
                                :on-success="handleAvatarSuccess"
                                :auto-upload="true"
                            >
                                <img
                                    class="avator"
                                    :src="util.reImg(value.avatar)"
                                />
                            </el-upload>
                        </div>
                    </div>

                    <div class="right pull-left">
                        <div class="right-wraper">
                            <p
                                class="userinfo-item user-name"
                                v-if="!nick_edit"
                            >
                                <span style="font-size:16px;float:left;color:#333;">{{value.nick}}</span>
                                <template v-if="userInfo.info.attestation != 2">
                                    <i class="valid tag">{{value.attestation_value}}</i>
                                </template>
                                <template v-else>
                                    <i
                                        class="valid tag"
                                        v-if="userInfo.info.user_type==1"
                                    >{{value.attestation_type_value}}极客</i>
                                    <i
                                        class="valid tag"
                                        v-else
                                    >{{value.attestation_type_value}}雇主</i>
                                </template>
                                <i
                                    class="jk-icon-bianjilanse cursorPointer"
                                    title="点击编辑昵称"
                                    @click="edit_nick"
                                ></i>
                            </p>

                            <p
                                v-else
                                style="width:100%;"
                            >
                                <el-input
                                    v-model="nick"
                                    placeholder="请输入昵称"
                                ></el-input>
                                <el-button
                                    slot="append"
                                    @click="save_nick"
                                >保存</el-button>
                            </p>

                            <p
                                class="adderess"
                                style="width:190%;"
                            >
                                <span
                                    class="userinfo-item"
                                    v-if="userInfo.info.user_type==1"
                                    style="display:inline-block;width:206px;"
                                >极客ID：{{value.geek_id}}</span>
                                <span
                                    class="userinfo-item"
                                    v-else
                                    style="display:inline-block;width:206px;"
                                >雇主ID：{{value.geek_id}}</span>

                                <span
                                    class="userinfo-item"
                                    style="display:inline-block;width:274px;margin-left:-20px;"
                                    v-show="value.attestation != 2 &&  value.user_type==1"
                                >邀请码：入驻后才会生成邀请码</span>
                                <span
                                    class="userinfo-item"
                                    style="display:inline-block;width:274px;margin-left:-20px;"
                                    v-show="value.attestation != 2 &&  value.user_type==2"
                                >邀请码：认证后才会生成邀请码</span>
                                <span
                                    class="userinfo-item"
                                    v-show="value.attestation == 2"
                                    style="display:inline-block;width:274px;margin-left:-20px;"
                                >
                                    邀请码：{{value.invitation_code}}
                                    <i
                                        class="jk-icon-fuzhi fuzhi cursorPointer"
                                        :data-clipboard-text="invitationUrl"
                                        @click="copy"
                                    ></i>
                                </span>

                                <span>{{value.area_value}}</span>
                            </p>

                            <p
                                class="adderess"
                                style="width:190%;"
                            >
                                <span style="display:inline-block;width:206px;">
                                    <span
                                        class="tit"
                                        v-if="value.user_type ==2"
                                    >发布项目：</span>
                                    <span
                                        class="tit"
                                        v-else
                                    >完成项目：</span>
                                    {{value.task_total}}次
                                </span>

                                <span style="display:inline-block;width:296px;">
                                    <span
                                        class="tit"
                                        v-if="value.user_type==1"
                                    >获得酬劳：</span>
                                    <span
                                        class="tit"
                                        v-else
                                    >已支付酬劳：</span>
                                    ￥{{value.reward_total | numFilter}}
                                </span>

                                <span>
                                    <span class="tit">信用评分：</span>
                                    <el-rate
                                        v-model="value.credence"
                                        disabled
                                        show-score
                                        text-color="#f59b15"
                                        score-template="{value}"
                                    ></el-rate>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "UcUserInfo",
    props: ["value"],
    data() {
        return {
            nick: "",
            nick_edit: false,
            imgUploadUrl: "", //图片上传地址
            invitationUrl: ""
        };
    },

    computed: {
        ...mapState(["userInfo"]),
        imgHeaders: function () {
            //图片上传头部信息
            return {
                token: sessionStorage.getItem("token"),
                timestamp: new Date().getTime(),
                secret: this.websiteConfigs.secret
            };
        }
    },

    created() {
        //加载图片上传头部信息
        this.imgUploadUrl =
            this.websiteConfigs.baseUrl + this.websiteConfigs.uploadUrl + "?group=user"; //获取上传地址

        //邀请码
        if (this.userInfo.info.attestation == 2) {
            this.invitationUrl =
                window.location.origin +
                "/login/index?type=register&code=" +
                this.userInfo.info.invitation_code;
        } else {
            this.invitationUrl = "认证用户才会生成邀请码哦";
        }
    },

    methods: {
        ...mapActions("userInfo", ["ajaxUserInfo"]),
        copy() {
            var clipboard = new this.Clipboard(".fuzhi");
            clipboard.on("success", () => {
                this.$message({
                    message: "复制成功",
                    type: "success"
                });
                clipboard.destroy();
            });
            clipboard.on("error", () => {
                console.log("该浏览器不支持自动复制");
                clipboard.destroy();
            });
        },
        edit_nick() {
            this.nick_edit = true;
            this.nick = this.value.nick;
        },
        save_nick() {
            if (!this.nick) {
                this.$message.error("请填写昵称");
                return;
            }
            this.post("/user/user/update_nick", { nick: this.nick }, data => {
                if (data.code != 200) {
                    this.$message.error(data.msg);
                    return;
                }
                this.ajaxUserInfo(); //刷新用户数据
                this.nick_edit = false;
            });
        },
        go_to() {
            //是否入驻（0.待入驻；1.已入驻；2.审核未通过；3.未入驻）
            if (this.userInfo.info.attestation == 2) {
                this.$router.push(
                    "/user/attest/edit?id=" +
                    this.userInfo.info.attestation_type
                ); //跳转到编辑认证信息页面
            } else if (
                this.userInfo.info.attestation == 3 ||
                this.userInfo.info.attestation == 0
            ) {
                this.$router.push("/user/attest"); //未入驻,跳转到入驻页面
            } else {
                this.$router.push("/user/index"); //都不是就返回用户首页
            }
        },

        //上传之前
        beforeUpload() {
            this.imgHeaders.timestamp = new Date().getTime();
        },

        //上传头像
        handleAvatarSuccess(res, file, callback) {
            let _this = this;
            _this.post(
                "/user/user/update_avatar",
                { avatar: res.data.file },
                function (r) {
                    if (r.code == 200) {
                        _this.ajaxUserInfo(); //刷新用户数据
                        return false;
                    }
                    _this.$message.error("头像更新失败");
                }
            );
        }
    }
};
</script>

<style lang="less" scoped>
@import "../../styles/ucUserInfo.less";
.userInfo-panner .user-name {
    display: inline-block;
}

.userInfo-panner .user-name i {
    font-size: 14px;
    display: inline-block;
    margin: 0;
    padding: 0px 4px;
    margin-left: 8px;
}
.userInfo-panner .right-wraper p {
    width: 100%;
}
.userInfo-panner .right-wraper p .el-input {
    width: 280px;
}
.userInfo-panner .right-wraper p .el-input .el-input__inner {
    width: 200px;
    float: left;
}
.userInfo-panner .ft-item {
    height: 26px;
}
.userInfo-panner .right-wraper p .el-input .el-input-group__append {
    padding: 0;
    margin: 0;
}
.userInfo-panner .adderess {
    margin: 0;
    margin-top: 4px;
}
.avatarBox {
    overflow: hidden;
    border-radius: 1000px;
    position: relative;
    background: #587ddc;
    .avatarImg {
        width: 80px;
        height: 80px;
    }
    .edit {
        font-size: 12px;
        position: absolute;
        bottom: 28px;
        margin-left: 28px;
        color: #fff;
        display: none;
    }
    img {
        height: 100% !important;
    }
}
.avatarBox:hover {
    img {
        opacity: 0;
    }
    .edit {
        display: block;
    }
}
.cursorPointer {
    cursor: pointer;
}
</style>





