<template>
    <div>
        <geek-view v-if="userInfo.info.user_type==1" />
        <employer-view v-else />
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import geekView from "./geek";
import employerView from "./employer";
export default {
    components: {
        geekView,
        employerView
    },
    metaInfo() {
        return {
            title: "用户中心 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            hah: true,
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    created() {
        // if (this.userInfo.isLogin == false) {//用户未登录
        //     this.$router.push("/login/index?type=login");
        // }
    },
};
</script>