<template>
    <div class="home">
        <section class="secton userinfo-sec" style="margin-bottom:0">
            <div class="section-l">
                <uc-user-info v-model="userInfo.info"></uc-user-info>
            </div>
        </section>

        <!--  已认证信息展示  -->
        <div class="attestation">
            <section class="secton attestation-sec">
                <div class="form-panner base-info-panner">
                    <div class="form-panner-bd bi-bd">
                        <div class="form-cont border-none">
                            <el-form ref="form" label-position="left">
                                <el-row :gutter="20">
                                    <div class="step2-info steps mt-0">
                                        <!-- 基础信息 -->
                                        <div class="info-inner base-info mt-20">
                                            <div class="info-card-panner border-all">
                                                <header class="panner-hd">
                                                    <h2 class="tit">
                                                        <span
                                                            class="line"
                                                            style="font-size:16px;"
                                                        >基础信息</span>
                                                    </h2>
                                                </header>

                                                <div class="panner-bd user-introduce">
                                                    <div class="user-info-wrap">
                                                        <el-row class="row-item">
                                                            <el-col :span="8">
                                                                <span class="tit">雇主昵称：</span>
                                                                <span>{{userInfo.info.nick}}</span>
                                                            </el-col>

                                                            <el-col :span="8">
                                                                <span class="tit">登录账号：</span>
                                                                <span>{{userInfo.info.mobile}}</span>
                                                            </el-col>

                                                            <!-- <el-col :span="8">
                                                                <span class="tit">隐私设置：</span>
                                                                <span>{{userInfo.info.privacy_value}}</span>
                                                            </el-col> -->
                                                        </el-row>

                                                        <el-row class="row-item">
                                                            <el-col :span="8">
                                                                <span class="tit">
                                                                    <span>雇主类型：</span>

                                                                    <el-button
                                                                        v-if="userInfo.info.attestation==0 || userInfo.info.attestation==3"
                                                                        type="primary"
                                                                        plain
                                                                        size="mini"
                                                                        style="height:24px;line-height:22px;padding:0 10px;margin:0;"
                                                                        class="btn valid"
                                                                        @click="go_to('userAttest')"
                                                                    >{{userInfo.info.attestation_value}}</el-button>

                                                                    <i
                                                                        class="status"
                                                                        v-else-if="userInfo.info.attestation==1"
                                                                        v-text="userInfo.info.attestation_value"
                                                                    ></i>

                                                                    <i class="status" v-else>
                                                                        <span>{{userInfo.info.attestation_type_value}}</span>
                                                                        <span>雇主</span>
                                                                    </i>
                                                                </span>
                                                            </el-col>

                                                            <el-col :span="8">
                                                                <span
                                                                    class="tit"
                                                                >认证状态：</span>
                                                                <span>{{userInfo.info.attestation_value}}</span>
                                                            </el-col>
                                                        </el-row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- 个人已认证信息  此处需要判断是单位还是个人 -->
                                        <div
                                            class="info-inner base-info"
                                            v-if="userInfo.info.attestation_type==1"
                                        >
                                            <div class="info-card-panner border-all">
                                                <header class="panner-hd">
                                                    <h2 class="tit">
                                                        <span
                                                            class="line"
                                                            style="font-size:16px;"
                                                        >认证信息</span>
                                                    </h2>
                                                </header>

                                                <div
                                                    class="panner-bd user-introduce"
                                                    style="padding-top:10px;"
                                                >
                                                    <div class="user-info-wrap">
                                                        <el-row class="row-item">
                                                            <el-col
                                                                :span="24"
                                                                style="margin-top:15px;"
                                                            >
                                                                <span class="tit">姓名:</span>
                                                                <span>{{userInfo.info.name}}</span>
                                                            </el-col>

                                                            <el-col
                                                                :span="24"
                                                                style="margin-top:15px;"
                                                            >
                                                                <span class="tit">身份证号码:</span>
                                                                <span>{{userInfo.attestation.idcard | idcard}}</span>
                                                                <span
                                                                    class="green"
                                                                    v-if="userInfo.info.attestation==2"
                                                                >认证通过</span>
                                                                <span
                                                                    class="yellow"
                                                                    v-if="userInfo.info.attestation==0"
                                                                >未认证</span>
                                                                <span
                                                                    class="yellow"
                                                                    v-if="userInfo.info.attestation==1"
                                                                >待审核</span>
                                                                <span
                                                                    class="yellow"
                                                                    v-if="userInfo.info.attestation==3"
                                                                >审核失败</span>
                                                            </el-col>
                                                        </el-row>
                                                        <el-row class="row-item">
                                                            <el-col :span="24">
                                                                <el-col :span="2">
                                                                    <span class="tit">身份证照片:</span>
                                                                </el-col>
                                                                <el-col
                                                                    :span="6"
                                                                    class="idcard_img"
                                                                >
                                                                    <el-image
                                                                        class="ml-10"
                                                                        :src="util.reImg(userInfo.attestation.idcard_picture[0])"
                                                                    >
                                                                        <div
                                                                            slot="placeholder"
                                                                            class="image-slot"
                                                                        >
                                                                            加载中
                                                                            <span class="dot">...</span>
                                                                        </div>
                                                                    </el-image>
                                                                </el-col>
                                                                <el-col
                                                                    :span="6"
                                                                    class="idcard_img"
                                                                >
                                                                    <el-image
                                                                        class="ml-10"
                                                                        :src="util.reImg(userInfo.attestation.idcard_picture[1])"
                                                                    >
                                                                        <div
                                                                            slot="placeholder"
                                                                            class="image-slot"
                                                                        >
                                                                            加载中
                                                                            <span class="dot">...</span>
                                                                        </div>
                                                                    </el-image>
                                                                </el-col>
                                                            </el-col>
                                                        </el-row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- 单位已认证信息  此处需要判断是单位还是个人 -->
                                        <div
                                            class="info-inner base-info mt-20"
                                            v-else-if="userInfo.info.attestation_type==2"
                                        >
                                            <div class="info-card-panner border-all">
                                                <header class="panner-hd">
                                                    <h2 class="tit">
                                                        <span
                                                            class="line"
                                                            style="font-size:16px;"
                                                        >认证信息</span>
                                                    </h2>
                                                </header>

                                                <div class="panner-bd user-introduce">
                                                    <div class="user-info-wrap">
                                                        <el-row class="row-item">
                                                            <el-col :span="8">
                                                                <span class="tit mr-5">单位名称:</span>
                                                                <span>{{userInfo.attestation.name}}</span>
                                                            </el-col>
                                                            <el-col :span="8">
                                                                <span class="tit mr-5">单位类型:</span>
                                                                <span
                                                                    v-text="userInfo.attestation.organ_type_value"
                                                                ></span>
                                                            </el-col>
                                                            <el-col :span="8">
                                                                <span class="tit mr-5">统一社会信用代码:</span>
                                                                <span>{{userInfo.attestation.organizational_code}}</span>
                                                            </el-col>
                                                        </el-row>
                                                        <el-row class="row-item">
                                                            <!-- <el-col :span="8">
                                                                <span class="tit mr-5">登录账号:</span>
                                                                <span>{{userInfo.info.mobile}}</span>
                                                            </el-col>-->
                                                            <el-col :span="8">
                                                                <span
                                                                    v-if="userInfo.attestation.organ_type==1"
                                                                    class="tit mr-5"
                                                                >单位证书:</span>
                                                                <span
                                                                    v-if="userInfo.attestation.organ_type==2"
                                                                    class="tit mr-5"
                                                                >机构代码证:</span>
                                                                <span
                                                                    v-if="userInfo.attestation.organ_type==3"
                                                                    class="tit mr-5"
                                                                >单位证书:</span>
                                                                <span
                                                                    class="blue"
                                                                    @click="showImg(userInfo.attestation.organ_file)"
                                                                    style="cursor:pointer"
                                                                >
                                                                    <i class="jk-icon-img mr-5"></i>点击查看
                                                                </span>
                                                            </el-col>

                                                            <el-col
                                                                :span="8"
                                                                style="padding-top:2px;"
                                                            >
                                                                <span class="tit mr-5">法人姓名:</span>
                                                                <span>{{userInfo.attestation.legal_name}}</span>
                                                            </el-col>

                                                            <el-col
                                                                :span="8"
                                                                style="padding-top:2px;"
                                                            >
                                                                <span class="tit mr-5">法人身份证号码:</span>
                                                                <span>{{userInfo.attestation.legal_idcard}}</span>
                                                            </el-col>
                                                        </el-row>
                                                        <el-row class="row-item">
                                                            <el-col :span="8">
                                                                <span class="tit mr-5">联系人姓名:</span>
                                                                <span>{{userInfo.attestation.contacts_name}}</span>
                                                            </el-col>

                                                            <el-col :span="8">
                                                                <span class="tit mr-5">联系人电话:</span>
                                                                <span>{{userInfo.attestation.contacts_phone}}</span>
                                                            </el-col>

                                                            <el-col :span="8">
                                                                <span class="tit mr-5">联系人邮箱:</span>
                                                                <span>{{userInfo.attestation.contacts_email}}</span>
                                                            </el-col>
                                                        </el-row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </el-row>
                            </el-form>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <!-- 未认证状态  -->
        <section
            v-if="userInfo.info.attestation==0"
            style="background:#fff;margin-top:-30px;padding:0 10px; 0 10px;"
        >
            <!-- 去认证 -->
            <div class="info-card-panner">
                <header class="panner-hd">
                    <h2 class="tit">
                        <span class="line" style="font-size:16px;">认证信息</span>
                    </h2>
                </header>
                <div class="panner-bd">
                    <router-link to="/user/attest">
                        <el-link
                            :underline="false"
                            class="valid-linebtn"
                            icon="jk-icon-qurenzheng"
                        >点这里立刻进行雇主认证</el-link>
                    </router-link>
                </div>
            </div>
        </section>

        <view-image v-model="isShow" :images-list="imgList" fit="contain" />
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UcUserInfo from "@/components/uc-user-info/UcUserInfo.vue";
export default {
    name: "ucIndex",
    components: {
        UcUserInfo // 用户信息
    },
    data() {
        return {
            isShow: false,
            imgList: []
        };
    },
    methods: {
        go_to(name) {
            this.$router.push({ name: name });
        },
        showImg(img) {
            this.imgList = [];
            this.imgList.push(this.util.reImg(img));
            this.isShow = true;
        },
        go_to(name) {
            //是否入驻（0.待入驻；1.已入驻；2.审核未通过；3.未入驻）
            if (this.userInfo.info.attestation == 2) {
                this.$router.push(
                    "/user/attest/edit?id=" +
                    this.userInfo.info.attestation_type
                ); //跳转到编辑认证信息页面
            } else if (
                this.userInfo.info.attestation == 3 ||
                this.userInfo.info.attestation == 0
            ) {
                this.$router.push("/user/attest"); //未入驻,跳转到入驻页面
            } else {
                this.$router.push("/user/index"); //都不是就返回用户首页
            }
        },
    },
    computed: {
        ...mapState(["userInfo"])
    }
};
</script>

<style lang="less" scoped>
@import "../../../../styles/ucUserInfo.less";
@import "../../../../styles/ucattestation.less";
.secton {
    margin: 0 0 20px 0;
    background: white;
}

.form-cont {
    padding-top: 0;
}

.user-introduce {
    p {
        margin-bottom: 5px;
        font-size: 14px;
        color: #333;
        line-height: 2;
    }
}

.info-card-panner {
    border: none;
}
.userinfo-sec {
    overflow: hidden;
    margin-bottom: 20px;
}

/deep/ .panner-footer {
    width: 500px;
}

/deep/ .userInfo-panner .userinfo-item {
    margin-right: 20px !important;
}

.achievement-list-item-inner {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none !important;
}

.tags-group {
    margin: 30px -10px;
    .el-tag {
        margin: 0 10px;
    }
}
.base-info {
    .panner-bd {
        border-bottom: none;
    }
}
.valid-linebtn {
    display: block;
    margin-top: 16px;
    width: 100%;
    height: 55px;
    text-align: center;
    line-height: 53px;
    border: 1px dashed #d9d9d9;
    color: #999;
    /deep/.el-link--inner {
        display: inline-block;
    }
    /deep/ .jk-icon-qurenzheng {
        margin-right: 10px;
    }
}
.info-card-panner:hover .edit-col {
    display: block;
}
.idcard_img {
    height: 225px !important;
    overflow: hidden;
}
</style>