<template>
    <div class="home">
        <div class="secton userinfo-sec">
            <uc-user-info v-model="userInfo.info"></uc-user-info>
        </div>

        <div class="attestation">
            <section class="secton attestation-sec">
                <!---------------基础信息-------------------->
                <div class="info-card-panner border-all">
                    <header class="panner-hd">
                        <h2 class="tit">
                            <span class="line" style="font-size:16px;">基础信息</span>
                        </h2>
                    </header>

                    <div class="panner-bd user-introduce">
                        <div class="user-info-wrap">
                            <el-row class="row-item">
                                <!-- <el-col :span="8">
                                    <span class="tit">极客名称：</span>
                                    <span>{{user.name}}</span>
                                </el-col>-->
                                <el-col :span="8">
                                    <span class="tit">极客昵称：</span>
                                    <span>{{user.nick}}</span>
                                </el-col>
                                <el-col :span="8">
                                    <span class="tit">登录账号：</span>
                                    <span>{{user.mobile}}</span>
                                </el-col>
                                <!-- <el-col :span="8">
                                    <span class="tit">隐私设置：</span>
                                    <span>{{user.privacy_value}}</span>
                                </el-col> -->
                            </el-row>

                            <el-row class="row-item">
                                <el-col :span="8">
                                    <span class="tit">
                                        <span class="tit">极客类型：</span>

                                        <el-button
                                            v-if="userInfo.info.attestation==0 || userInfo.info.attestation==3"
                                            type="primary"
                                            plain
                                            size="mini"
                                            class="btn valid"
                                            style="height:24px;line-height:22px;padding:0 10px;margin:0;"
                                            @click="go_to('userAttest')"
                                        >{{userInfo.info.attestation_value}}</el-button>

                                        <i
                                            class="status"
                                            v-else-if="userInfo.info.attestation==1"
                                            v-text="userInfo.info.attestation_value"
                                        ></i>

                                        <i class="status" v-else>
                                            <span>{{userInfo.info.attestation_type_value}}</span>
                                            <span>极客</span>
                                        </i>
                                    </span>
                                </el-col>

                                <el-col :span="8">
                                    <span class="tit">认证状态：</span>
                                    <span>{{userInfo.info.attestation_value}}</span>
                                </el-col>

                                <!-- <el-col :span="8">
                                    <span class="tit">电子邮箱：</span>
                                    <span>{{user.email?user.email:'未设置'}}</span>
                                </el-col>-->
                            </el-row>

                            <el-row class="row-item">
                                <!-- <el-col :span="8">
                                    <span class="tit">所在城市：</span>
                                    <span>{{user.area_value}}</span>
                                </el-col>-->

                                <!-- <el-col :span="8">
                                    <span class="tit">是否隐藏认证名称：</span>
                                    <span>{{user.hide_name==1?'是':'否'}}</span>
                                </el-col>-->
                            </el-row>
                        </div>
                    </div>
                </div>

                <!---------------入驻信息-------------------->
                <div
                    v-if="attestation != '' && attestation.name"
                    class="info-card-panner border-all"
                >
                    <header class="panner-hd">
                        <h2 class="tit">
                            <span class="line" style="font-size:16px;">入驻信息</span>
                        </h2>
                    </header>

                    <!--个人极客-->
                    <template v-if="user.attestation_type==1">
                        <div class="panner-bd user-introduce">
                            <div class="user-info-wrap">
                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">入驻实名：</span>
                                        <span>{{attestation.name}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">入驻类型：</span>
                                        <span>{{attestation.geek_type_value}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">入驻状态：</span>
                                        <span>{{attestation.is_pass_value}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">身份证号：</span>
                                        <span>{{attestation.idcard}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">极客性别：</span>
                                        <span>{{attestation.sex_value}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">出生年月：</span>
                                        <span>{{attestation.birthday}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">毕业院校：</span>
                                        <span>{{attestation.college}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">工作经验：</span>
                                        <span>{{attestation.experience==1?'有':'没有'}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">参加工作：</span>
                                        <span>{{attestation.experience_date!=0?attestation.experience_date:''}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">详细地址：</span>
                                        <span>{{user.address?user.address:"未设置"}}</span>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </template>

                    <!--工作室极客-->
                    <template v-if="user.attestation_type==2">
                        <div class="panner-bd user-introduce">
                            <div class="user-info-wrap">
                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">工作室名称：</span>
                                        <span>{{attestation.name}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">工作地址：</span>
                                        <span>{{attestation.address}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">工作室区域：</span>
                                        <span>{{attestation.area_value}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">入驻类型：</span>
                                        <span>{{attestation.geek_type_value}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">入驻状态：</span>
                                        <span>{{user.attestation_value}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">负责人姓名：</span>
                                        <span>{{attestation.legal_name}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">负责人身份证号：</span>
                                        <span>{{attestation.legal_idcard}}</span>
                                    </el-col>

                                    <el-col :span="8">
                                        <span class="tit">负责人职业资格证书：</span>
                                        <span>{{attestation.legal_credentials?attestation.legal_credentials:'无'}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <!-- <el-col :span="8">
                                        <span class="tit">负责人电话：</span>
                                        <span>{{attestation.legal_mobile!=0?attestation.legal_mobile:'未设置'}}</span>
                                    </el-col>-->

                                    <el-col :span="8">
                                        <span class="tit">联系人姓名：</span>
                                        <span>{{attestation.contacts_name}}</span>
                                    </el-col>

                                    <el-col :span="8">
                                        <span class="tit">联系人电话：</span>
                                        <span>{{attestation.contacts_mobile}}</span>
                                    </el-col>

                                    <el-col :span="8">
                                        <span class="tit">联系人邮箱：</span>
                                        <span>{{attestation.contacts_email}}</span>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </template>

                    <!--单位极客-->
                    <template v-if="user.attestation_type==3">
                        <div class="panner-bd user-introduce">
                            <div class="user-info-wrap">
                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">单位名称：</span>
                                        <span>{{attestation.name}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">单位类型：</span>
                                        <span>{{attestation.organ_type_value}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">统一社会信用代码：</span>
                                        <span>{{attestation.organizational_code}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">所属行业：</span>
                                        <span>{{attestation.industry}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">单位证书：</span>
                                        <span
                                            @click="showImg(attestation.organ_certificate)"
                                            v-if="attestation.organ_certificate"
                                            style="color:#587ddc;cursor:pointer"
                                        >点击查看</span>
                                        <span v-else>无</span>
                                    </el-col>

                                    <el-col :span="8">
                                        <span class="tit">单位区域：</span>
                                        <span>{{attestation.area_value}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">单位地址：</span>
                                        <span>{{attestation.address}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">法人姓名：</span>
                                        <span>{{attestation.legal_name}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">法人身份证号：</span>
                                        <span>{{attestation.legal_idcard}}</span>
                                    </el-col>

                                    <el-col :span="8">
                                        <span class="tit">法人电话：</span>
                                        <span>{{attestation.legal_mobile!=0?attestation.legal_mobile:'未设置'}}</span>
                                    </el-col>
                                </el-row>

                                <el-row class="row-item">
                                    <el-col :span="8">
                                        <span class="tit">联系人姓名：</span>
                                        <span>{{attestation.contacts_name}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">联系人电话：</span>
                                        <span>{{attestation.contacts_mobile}}</span>
                                    </el-col>
                                    <el-col :span="8">
                                        <span class="tit">联系人邮箱：</span>
                                        <span>{{attestation.contacts_email}}</span>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </template>
                </div>

                <!---------------能力信息-------------------->
                <div
                    v-if="attestation != '' && attestation.ability_tags"
                    class="info-card-panner border-all"
                >
                    <header class="panner-hd">
                        <h2 class="tit">
                            <span
                                class="line"
                                style="font-size:16px;"
                                v-if="user.attestation_type==1"
                            >个人能力</span>
                            <span
                                class="line"
                                style="font-size:16px;"
                                v-if="user.attestation_type==2"
                            >工作室能力</span>
                            <span
                                class="line"
                                style="font-size:16px;"
                                v-if="user.attestation_type==3"
                            >单位能力</span>
                        </h2>

                        <div v-if="attestation.ability_tags" class="panner-bd user-introduce">
                            <div
                                class="nodata-tips"
                                v-if="attestation.ability_tags.length == 0"
                            >暂未填写</div>
                            <div class="tags-group">
                                <el-tag
                                    :disable-transitions="false"
                                    v-for="(item,index) in attestation.ability_tags"
                                    :key="index"
                                    style="font-size:14px;"
                                >{{item}}</el-tag>
                            </div>
                        </div>
                    </header>
                </div>

                <!---------------极客简介-------------------->
                <div
                    v-if="attestation != '' && attestation.introduction"
                    class="info-card-panner border-all"
                >
                    <header class="panner-hd">
                        <h2 class="tit">
                            <span class="line" style="font-size:16px;">极客简介</span>
                        </h2>

                        <div class="panner-bd user-introduce">
                            <div v-html="attestation.introduction"></div>
                        </div>
                    </header>
                </div>

                <!---------------人员信息-------------------->
                <div
                    v-if="attestation != '' && attestation.personal && (user.attestation_type==2 || user.attestation_type==3)"
                    class="info-card-panner border-all"
                >
                    <header class="panner-hd">
                        <h2 class="tit">
                            <span class="line" style="font-size:16px;">人员信息</span>
                        </h2>

                        <div v-if="attestation.personal" class="panner-bd user-introduce">
                            <div class="nodata-tips" v-if="attestation.personal.length == 0">暂未填写</div>

                            <article
                                v-else
                                class="media-box mb-20"
                                v-for="(item,index) in attestation.personal"
                                :key="index"
                            >
                                <el-row>
                                    <el-col :span="2">
                                        <img
                                            class="avator"
                                            :src="util.reImg(item.avatar)"
                                            style="border-radius:1000px;width:80px;"
                                        />
                                    </el-col>
                                    <el-col :span="21" :offset="1">
                                        <el-row class="mt-5">
                                            <el-col :span="22">
                                                {{item.name}}
                                                <i
                                                    class="color-main ml-10"
                                                    :class="[item.sex==1?'jk-icon-male':'jk-icon-female']"
                                                ></i>
                                            </el-col>
                                        </el-row>

                                        <el-row class="mt-10 ry_list">
                                            <el-col :span="24">
                                                <span class="grey">年龄：</span>
                                                <span style="margin-right:30px;">{{item.age}}岁</span>
                                            </el-col>

                                            <el-col :span="24">
                                                <span class="grey">学历：</span>
                                                <span style="margin-right:30px;">{{item.education}}</span>
                                            </el-col>

                                            <el-col :span="24">
                                                <span class="grey">专业：</span>
                                                <span style="margin-right:30px;">{{item.profession}}</span>
                                            </el-col>

                                            <el-col :span="24">
                                                <span class="grey">职称：</span>
                                                <span
                                                    style="margin-right:30px;"
                                                >{{item.professional_qualifications}}</span>
                                            </el-col>

                                            <el-col :span="24">
                                                <span class="grey">证书：</span>
                                            </el-col>
                                        </el-row>

                                        <el-row v-if="item.certificate">
                                            <el-col :span="16">
                                                <div
                                                    class="cer-item"
                                                    v-for="(certifi,ci) in item.certificate"
                                                    :key="ci"
                                                >
                                                    <el-col :span="9">{{certifi.title}}</el-col>

                                                    <el-col
                                                        :span="13"
                                                        class="grey"
                                                    >证书编号：{{certifi.code}}</el-col>

                                                    <el-col :span="2">
                                                        <el-link
                                                            type="primary"
                                                            @click="showImg(certifi.image)"
                                                        >查看</el-link>
                                                    </el-col>
                                                </div>
                                            </el-col>
                                        </el-row>

                                        <div class="line-orientation"></div>
                                    </el-col>
                                </el-row>
                            </article>
                        </div>
                    </header>
                </div>

                <!---------------业绩信息-------------------->
                <div
                    v-if="attestation != '' && attestation.achievement"
                    class="info-card-panner border-all"
                >
                    <header class="panner-hd">
                        <h2 class="tit">
                            <span class="line" style="font-size:16px;">业绩信息</span>
                        </h2>

                        <div v-if="attestation.achievement" class="panner-bd user-introduce">
                            <div class="nodata-tips" v-if="attestation.achievement.length == 0">暂未填写</div>
                            <article
                                v-else
                                class="item"
                                v-for="(item,index) in attestation.achievement"
                                :key="index"
                                style="margin-bottom:20px;"
                            >
                                <el-row class="yj_list">
                                    <el-col :span="24">
                                        <span class="tit light-black">项目名称：</span>
                                        <span>{{item.title}}</span>
                                    </el-col>

                                    <el-col :span="24">
                                        <span class="tit light-black">项目时间：</span>
                                        <span>{{item.starttime}} - {{item.endtime}}</span>
                                    </el-col>

                                    <el-col :span="24">
                                        <span class="tit light-black" style="border:none">
                                            负
                                            <span style="margin-right:3px;"></span>
                                            责
                                            <span
                                                style="margin-right:3px;"
                                            ></span>
                                            人：
                                        </span>
                                        <span class="right-cont">{{item.leader[0]}}</span>
                                    </el-col>

                                    <el-col :span="24">
                                        <span class="tit light-black" style="border:none">项目描述：</span>
                                        <span>{{item.description}}</span>
                                    </el-col>

                                    <el-col :span="24">
                                        <span class="tit light-black" style="border:none">项目职责：</span>
                                        <span>{{item.responsibility}}</span>
                                    </el-col>
                                </el-row>
                            </article>
                        </div>
                    </header>
                </div>

                <!---------------资格证书-------------------->
                <div
                    v-if="attestation != '' && attestation.credentials &&  user.attestation_type!=2"
                    class="info-card-panner border-all"
                >
                    <header class="panner-hd">
                        <h2 class="tit">
                            <span class="line" style="font-size:16px;">资格证书</span>
                        </h2>

                        <div class="panner-bd user-introduce">
                            <div class="achievement-items-wraper" style="margin-top: 0">
                                <div
                                    class="nodata-tips"
                                    v-if="attestation.credentials.length == 0"
                                >暂未填写</div>

                                <el-row
                                    v-else
                                    class="achievement-list-item-inner"
                                    v-for="(item1,index) in attestation.credentials"
                                    :key="index"
                                >
                                    <el-col :span="18">{{item1.title}}</el-col>
                                    <el-col :span="4">{{item1.time}}</el-col>
                                    <el-col :span="2">
                                        <el-link type="primary" @click="showImg(item1.image)">查看</el-link>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </header>
                </div>
            </section>
        </div>
        <view-image v-model="isShow" :images-list="imgList" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import UcUserInfo from "@/components/uc-user-info/UcUserInfo.vue";
export default {
    name: "ucIndex",
    components: {
        UcUserInfo, // 用户基本信息
    },
    data() {
        return {
            user: '',
            attestation: '',
            imgList: [],
            isShow: false
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    created() {
        this.user = this.userInfo.info;
        this.attestation = this.userInfo.attestation;
    },
    methods: {
        showImg(img) {
            this.imgList = [];
            this.imgList.push(this.util.reImg(img));
            this.isShow = true;
        },
        go_to(name) {
            //是否入驻（0.待入驻；1.已入驻；2.审核未通过；3.未入驻）
            if (this.userInfo.info.attestation == 2) {
                this.$router.push(
                    "/user/attest/edit?id=" +
                    this.userInfo.info.attestation_type
                ); //跳转到编辑认证信息页面
            } else if (
                this.userInfo.info.attestation == 3 ||
                this.userInfo.info.attestation == 0
            ) {
                this.$router.push("/user/attest"); //未入驻,跳转到入驻页面
            } else {
                this.$router.push("/user/index"); //都不是就返回用户首页
            }
        },
    },
};
</script>

<style lang="less" scoped>
@import "../../../../styles/ucattestation.less";
.secton {
    margin: 0 0 20px 0;
}
.form-cont {
    padding-top: 0;
}
.user-introduce {
    p {
        margin-bottom: 5px;
        font-size: 14px;
        color: #333;
        line-height: 2;
    }
}
.info-card-panner {
    border: none;
}
.userinfo-sec {
    overflow: hidden;
    margin-bottom: 0;
    background: white;
}
.attestation {
    background: white;
}

/deep/ .panner-footer {
    width: 500px;
}
/deep/ .userInfo-panner .userinfo-item {
    margin-right: 20px !important;
}
.achievement-list-item-inner {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none !important;
}
.tags-group {
    margin: 10px -10px;
    .el-tag {
        margin: 0 10px;
    }
}
.base-info {
    .panner-bd {
        border-bottom: none;
    }
}
.info-card-panner {
    span {
        color: #666;
    }
    .tit {
        color: #666;
    }
}
.user-introduce {
    .el-row {
        margin-bottom: 10px;
    }
    .light-black {
        line-height: 20px;
    }
}

/**人员 */
.ry_list {
    .el-col-24 {
        margin-bottom: 6px;
    }
}

/**业绩 */
.yj_list {
    border-bottom: 1px solid #ddd;
    .el-col-24 {
        margin-bottom: 10px;
    }
}
</style>


